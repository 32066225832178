<template>
  <div>
    <sm-editable-list
      ref="editableList"
      :controllerName="controllerName"
      :breadcrumbs="breadcrumbs"
      :tableCaption="tableCaption"
      :tableHeaders="tableHeaders"
    >
      <template v-slot:type="{ row }">
        {{
          row.type &&
          zadarmaCallsTypes.data.length &&
          zadarmaCallsTypes.data.find((item) => item.value === row.type)
            ? zadarmaCallsTypes.data.find((item) => item.value === row.type)
                .name
            : ''
        }}
      </template>

      <template v-slot:status="{ row }">
        {{
          row.status &&
          zadarmaCallsStatuses.data.length &&
          zadarmaCallsStatuses.data.find((item) => item.value === row.status)
            ? zadarmaCallsStatuses.data.find(
                (item) => item.value === row.status
              ).name
            : ''
        }}
      </template>

      <template v-slot:customActions="{ row }">
        <div class="editable-list__datatable-buttons">
          <!-- <sm-tooltip text="Отправить СМС">
            <button
              class="editable-list__datatable-button"
              @click="onOpenSmsModal(row.id)"
            >
              <sm-icon viewBox="0 0 512 512" name="sms" />
            </button>
          </sm-tooltip> -->
          <button
            v-show="+row.duration"
            class="editable-list__datatable-button"
            @click="onDownloadCall(row.id)"
          >
            <sm-icon name="document-download" />
          </button>
        </div>
      </template>
    </sm-editable-list>
    <sm-form-modal
      v-model="smsModalForm"
      :fields="smsModalFields"
      :show="showSmsModalForm"
      :modal-title="modalSendSmsTitle"
      :isLoadingSaveButton="isLoadingSendingSms"
      @close="onCancelSendSms"
      @save="onSendSms"
      @cancel="onCancelSendSms"
    />
  </div>
</template>

<script>
// vuex
import { mapState, mapActions } from 'vuex';
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';
import SmIcon from '@/components/common/SmIcon.vue';
import SmFormModal from '@/components/common/modals/SmFormModal.vue';
// import SmTooltip from '@/components/common/SmTooltip.vue';

export default {
  name: 'SipCalls',

  components: {
    SmEditableList,
    SmIcon,
    SmFormModal,
    // SmTooltip,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'SipCalls',
      tableCaption: 'Звонки по телемаркетингу',
      breadcrumbs: [
        {
          text: 'Телемаркетинг',
          route: { name: 'TelemarketingMain' },
        },
        {
          text: 'Звонки по телемаркетингу',
        },
      ],
      tableHeaders: [
        { text: 'Тип звонка', alias: 'type' },
        { text: 'Статус звонка', alias: 'status', order: 'status' },
        { text: 'Телефонный номер', alias: 'phone', order: 'phone' },
        { text: 'Время звонка', alias: 'time' },
        {
          text: 'Длительность звонка в секундах',
          alias: 'duration',
          order: 'duration',
        },
        { text: '', alias: 'customActions' },
      ],
      isLoadingSendingSms: false,
      showSmsModalForm: false,
      smsModalForm: {
        phone: null,
        text: 'Установите мобильное приложение ГранельЖКХ https://clck.ru/34ZLef',
        sendShortLinks: false,
      },
      smsModalFields: [
        {
          type: 'textarea',
          key: 'text',
          label: 'Текст сообщения:',
        },
        {
          type: 'checkbox',
          key: 'sendShortLinks',
          label: 'Сокращать ссылки',
        },
      ],
      currentPhone: null,
    };
  },

  computed: {
    ...mapState({
      zadarmaCallsTypes: (state) => state.common.zadarmaCallsTypes,
      zadarmaCallsStatuses: (state) => state.common.zadarmaCallsStatuses,
    }),

    modalSendSmsTitle() {
      return `Отправка СМС на номер ${this.currentPhone}`;
    },
  },

  created() {
    this.getCommonList({ name: 'zadarmaCallsTypes' });
    this.getCommonList({ name: 'zadarmaCallsStatuses' });
  },

  methods: {
    ...mapActions({
      downloadFile: 'editableList/downloadFile',
      getCommonList: 'common/getCommonList',
      sendSms: 'telemarketing/sendSms',
    }),

    onDownloadCall(id) {
      this.downloadFile({
        name: this.controllerName,
        id,
        type: 'file',
      });
    },

    onOpenSmsModal(id) {
      const callsList = this.$refs.editableList.processedList;
      const currentCall = callsList.find((call) => call.id === id);
      this.currentPhone = currentCall.phone;

      this.smsModalForm.phone = this.currentPhone;
      this.showSmsModalForm = true;
    },

    onSendSms() {
      this.isLoadingSendingSms = true;

      this.sendSms(this.smsModalForm)
        .then((result) => {
          if (result.isSucceed) {
            this.$notify({
              header: 'Смс отправлено',
              text: `Смс отправлено на номер ${this.smsModalForm.phone}`,
              type: 'success',
              timer: 5000,
            });
          }
        })
        .finally(() => {
          this.isLoadingSendingSms = false;
          this.showSmsModalForm = false;
        });
    },

    onCancelSendSms() {
      this.showSmsModalForm = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.tooltip {
  margin-right: 5px;
}
</style>
